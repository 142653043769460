/* eslint-disable @typescript-eslint/ban-ts-comment */
import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// import { find as findParcels } from 'redux/modules/parcels';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';

const ScanPickupLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-ScanPickup' */ './ScanPickup'),
  {
    fallback: <LoadingComponent />,
  }
);
const ScanPickup = (prop: any) => <ScanPickupLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises: any[] = [];
  },
})(ScanPickup);
