import loadable from '@loadable/component';
import { find as findParcels } from 'redux/modules/parcels';
import { find as findExternalServices } from 'redux/modules/externalServices';
import { find as findExternalServiceProviders } from 'redux/modules/externalServiceProviders';
import { find as findCountryAddressTypes } from 'redux/modules/countryAddressTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';

import LoadingComponent from 'components/Loaders/LoadingComponent';
import { batch } from 'react-redux';
import { find as findAccountTaxPrograms } from '../../../redux/modules/accountTaxPrograms';

const NewParcelLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-newparcel' */ './NewParcel'),
  {
    fallback: <LoadingComponent />,
  }
);

const NewParcel = (prop: any) => <NewParcelLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { getState, dispatch } }: any) => {
    const promises: any = [];

    const state = getState();

    // external services
    if (!state.externalServices.result.servicesList) {
      promises.push(() =>
        dispatch(
          findExternalServices('servicesList', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    if (!state.externalServiceProviders.result.serviceProvidersList) {
      promises.push(() =>
        dispatch(
          findExternalServiceProviders('serviceProvidersList', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    if (!state.countryAddressTypes.result.serviceCountryAddressTypes) {
      promises.push(() =>
        dispatch(
          findCountryAddressTypes('serviceCountryAddressTypes', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    if (params.id) {
      promises.push(() =>
        dispatch(
          findParcels('editMyParcel', {
            query: {
              $paginate: false,
              uid: params.id,
              include: ['Account', 'Label', 'Sender', 'Recipient', 'Dispatch', 'Unitizer'],
            },
          })
        ).catch(() => null)
      );
    }

    promises.push(() =>
      dispatch(
        findAccountTaxPrograms('accountTaxProgramsFind', {
          query: {
            $paginate: false,
          },
        })
      ).catch(() => null)
    );

    await new Promise((resolve) => {
      batch(async () => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
        const finalPromises: any = promises.map((func) => func());
        await Promise.all(finalPromises);
        // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      });
    });
  },
})(NewParcel);
