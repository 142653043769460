import loadable from '@loadable/component';
import { find as findParcels } from 'redux/modules/parcels';
import { find as findInternalServices } from 'redux/modules/internal-services';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const LabelParcelLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-LabelParcel' */ './LabelParcel'),
  {
    fallback: <LoadingComponent />,
  }
);

const LabelParcel = (prop: any) => <LabelParcelLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises = [];

    promises.push(
      dispatch(
        findParcels(`parcelId-${params.id}`, {
          query: {
            $paginate: false,
            uid: params.id,
            include: [
              'AccountExtra',
              'LabelComplete',
              'Sender',
              'Recipient',
              'Dispatch',
              'Unitizer',
              'IncludeAllLogos',
              'ParcelTrackingQueue',
            ],
          },
        })
      ).catch(console.error),
      dispatch(
        findInternalServices(`internal-services-${params.id}`, {
          query: {
            $paginate: false,
          },
        })
      ).catch(console.error)
    );

    await Promise.all(promises);
  },
})(LabelParcel);
