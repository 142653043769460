import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
import { batch } from 'react-redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { find as findMarkets } from '../../../redux/modules/currencyMarkets';
import { find as findAccountTaxPrograms } from '../../../redux/modules/accountTaxPrograms';

const StoreAmazonLoadable: any = loadable(() => import('./StoreAmazonOrders'), {
  fallback: <LoadingComponent />,
});

const StoreAmazon = (prop: any) => <StoreAmazonLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises: any = [];

    const state = getState();

    // external services
    if (!state.currencyMarkets.result.all) {
      promises.push(() =>
        dispatch(
          findMarkets('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    promises.push(() =>
      dispatch(
        findAccountTaxPrograms('accountTaxProgramsFind', {
          query: {
            $paginate: false,
          },
        })
      ).catch(() => null)
    );

    await new Promise((resolve) => {
      batch(async () => {
        const finalPromises = promises.map((func: any) => func());
        await Promise.all(finalPromises);
        resolve(true);
      });
    });
  },
})(StoreAmazon);
