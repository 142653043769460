import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { find as findAccount } from 'redux/modules/accounts';
import { batch } from 'react-redux';

const AccountTaxProgramLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-AccountTaxProgram' */ './AccountTaxProgram'),
  {
    fallback: <LoadingComponent />,
  }
);

const AccountTaxProgram = (prop: any) => <AccountTaxProgramLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises: any = [];

    if (params.id) {
      promises.push(() =>
        dispatch(
          findAccount('searchAccountForTaxProgramsList', {
            query: {
              $paginate: false,
              uid: params.id,
            },
          })
        ).catch(() => null)
      );
    }

    await new Promise((resolve) => {
      batch(async () => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
        const finalPromises: any = promises.map((func) => func());
        await Promise.all(finalPromises);
        // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      });
    });
  },
})(AccountTaxProgram);
