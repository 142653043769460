import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const DispatchListLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-CustomAlertList' */ './DispatchList'),
  {
    fallback: <LoadingComponent />,
  }
);
export default DispatchListLoadable;
