/* eslint-disable max-len */
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocationIcon from '@mui/icons-material/LocationCity';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/AddBoxOutlined';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddchartIcon from '@mui/icons-material/Addchart';
import RouteIcon from '@mui/icons-material/AltRouteOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import PickupIcon from '@mui/icons-material/ArrowForward';
import BuildIcon from '@mui/icons-material/Build';
import DutiesCostCalculator from '@mui/icons-material/CalculateOutlined';
import { FaBarcode } from '@react-icons/all-files/fa/FaBarcode';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ImportIcon from '@mui/icons-material/ImportExport';
import LabelIcon from '@mui/icons-material/Label';
import LanguageIcon from '@mui/icons-material/Language';
import WeightIcon from '@mui/icons-material/LineWeight';
import ListIcon from '@mui/icons-material/ListAltOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LogingLogsIcon from '@mui/icons-material/LoginOutlined';
import MoneyIcon from '@mui/icons-material/MonetizationOn';
import PricingIcon from '@mui/icons-material/Money';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import GlobeIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
// import PdfIcon from '@mui/icons-material/PictureAsPdf';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import UsersIcon from '@mui/icons-material/SupervisedUserCircle';
import TrackingIcon from '@mui/icons-material/TrackChanges';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import ParcelsIcon from '@mui/icons-material/Work';
import KeyIcon from '@mui/icons-material/Key';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MultiRouteIcon from '@mui/icons-material/AccountTreeRounded';
import { IoIosQrScanner } from '@react-icons/all-files/io/IoIosQrScanner';
import { IoIosAirplane } from '@react-icons/all-files/io/IoIosAirplane';
import { IoIosBarcode } from '@react-icons/all-files/io/IoIosBarcode';
import { IoIosBriefcase } from '@react-icons/all-files/io/IoIosBriefcase';
import { IoMdAirplane } from '@react-icons/all-files/io/IoMdAirplane';

import QuickbooksIcon from 'components/Icons/Quickbooks';
import TrackingCodeIcon from '@mui/icons-material/Code';

export interface MuiPage {
  badge?: boolean;
  label: string;
  roles?: string[];
  pathname?: string;
  children?: MuiPage[];
  disableDrawer?: boolean;
  icon?: any;
  /**
   * Pages are considered to be ordered depth-first.
   * If a page should be excluded from this order, set `order: false`.
   * You want to set `ordered: false` if you don't want the page to appear in an ordered list e.g. for previous/next page navigation.
   */
  ordered?: boolean;
  /**
   * Props spread to the Link component
   */
  linkProps?: Record<string, unknown>;
  subheader?: string;
  /**
   * Overrides the default page title.
   */
  title?: string;
}

export interface OrderedMuiPage extends MuiPage {
  ordered?: true;
}

const pages: readonly MuiPage[] = [
  {
    label: 'Dashboard',
    icon: DashboardIcon,
    pathname: '/admin/dashboard',
    roles: [
      'admin',
      'customer',
      'wholesaler',
      'retailer',
      'reseller',
      'reseller_customer',
      'wholesaler_customer',
      'dev',
      'accounting',
      'accounting_chief',
      'warehouse',
      'analyst',
      'shipping_marketplace',
    ],
  },
  {
    label: 'Accounts',
    icon: UsersIcon,
    roles: [
      'admin',
      'accounting',
      'accounting_chief',
      'warehouse',
      'reseller',
      'retailer',
      'wholesaler',
    ],
    children: [
      {
        label: 'Users',
        icon: AccountCircleIcon,
        pathname: '/admin/users',
        roles: ['admin', 'accounting', 'accounting_chief'],
      },
      {
        label: 'Accounts',
        icon: AccountTreeIcon,
        pathname: '/admin/accounts',
        roles: ['admin', 'accounting', 'accounting_chief'],
      },
      // {
      //   label: 'Accounts pending',
      //   icon: ManageAccountsIcon,
      //   pathname: '/admin/accounts/pending_review',
      //   roles: ['admin', 'accounting', 'accounting_chief'],
      // },
      {
        label: 'White Label',
        icon: LabelIcon,
        roles: ['admin', 'warehouse', 'reseller', 'retailer', 'wholesaler'],
        pathname: '/admin/white-labels',
      },
      {
        label: 'HS Codes Authorizations',
        icon: () => <AppsIcon />,
        pathname: '/admin/harmonized-codes-restrictions-auth',
        roles: ['admin', 'warehouse'],
      },
      {
        label: 'Good Categories',
        icon: () => <AppsIcon />,
        pathname: '/admin/good-categories',
        roles: ['admin', 'warehouse'],
      },
      {
        label: 'HS Codes Categorization',
        icon: () => <AppsIcon />,
        pathname: '/admin/account-hs-code-categorization',
        roles: ['admin', 'warehouse'],
      },
      {
        label: 'HS Codes Interchange',
        icon: () => <AppsIcon />,
        pathname: '/admin/harmonized-codes-interchange',
        roles: ['admin', 'warehouse'],
      },
    ],
  },
  {
    label: 'Parcels',
    icon: ParcelsIcon,
    roles: [
      'admin',
      'warehouse',
      'analyst',
      'reseller',
      'retailer',
      'wholesaler',
      'reseller_customer',
      'read_only',
    ],
    badge: true,
    badgeCallback: 'loadParcelsPendingReview',
    badgeVar: 'countParcelsPendingReview',
    children: [
      {
        label: 'Add New',
        icon: AddIcon,
        pathname: '/admin/edit-parcel',
        roles: [
          'admin',
          'customer',
          'wholesaler',
          'retailer',
          'reseller',
          'reseller_customer',
          'wholesaler_customer',
          'dev',
          'accounting',
          'accounting_chief',
          'warehouse',
          'analyst',
          'shipping_marketplace',
        ],
      },
      {
        label: 'All Parcels',
        icon: ListIcon,
        pathname: '/admin/parcels',
      },
      {
        label: 'Imports',
        icon: ImportIcon,
        pathname: '/admin/parcel-imports',
        roles: [
          'admin',
          'customer',
          'wholesaler',
          'retailer',
          'reseller',
          'reseller_customer',
          'wholesaler_customer',
          'dev',
          'accounting',
          'accounting_chief',
          'warehouse',
          'analyst',
          'shipping_marketplace',
        ],
      },
      {
        label: 'Import 3PL Parcels',
        icon: ImportIcon,
        pathname: '/admin/parcel-imports-dl-packet',
        roles: ['admin', 'import_3pl_parcels'],
      },
      {
        label: 'Scan Zone',
        icon: IoIosQrScanner,
        pathname: '/admin/parcel-scan',
        roles: ['admin', 'wholesaler', 'warehouse'],
      },
      {
        label: 'Scan Zone Trial',
        icon: IoIosQrScanner,
        pathname: '/admin/parcel-scanv2',
        roles: ['admin', 'wholesaler', 'warehouse'],
      },
      {
        label: 'Origin Scan',
        icon: IoIosQrScanner,
        pathname: '/admin/origin-scan',
        roles: ['admin', 'wholesaler', 'warehouse'],
      },
      {
        label: 'Tracking Events',
        icon: TrackingIcon,
        pathname: '/admin/tracking-events',
        roles: ['admin', 'wholesaler', 'warehouse'],
      },
      {
        label: 'Duties & Tax Calculator',
        icon: DutiesCostCalculator,
        pathname: '/admin/duties-cost-calculator',
        roles: ['admin', 'warehouse', 'reseller', 'retailer', 'wholesaler'],
      },
      {
        label: 'Weight Adjustment',
        icon: BuildIcon,
        pathname: '/admin/parcel-tools',
        roles: ['admin', 'wholesaler', 'warehouse'],
      },
      {
        label: 'Parcels Taxed Pendings',
        icon: ListIcon,
        pathname: '/admin/parcels/taxed-pending',
        roles: ['admin', 'wholesaler', 'warehouse'],
      },
      {
        label: 'Parcels Taxed Paids',
        icon: ListIcon,
        pathname: '/admin/parcels/taxed-paid',
        roles: ['admin', 'wholesaler', 'warehouse'],
      },
    ],
  },
  {
    label: 'Pickups',
    icon: PickupIcon,
    roles: ['admin', 'warehouse', 'reseller', 'retailer', 'wholesaler'],
    children: [
      {
        label: 'Request Pickup',
        icon: AddIcon,
        pathname: '/admin/new-pickup',
      },
      {
        label: 'Pending',
        icon: ListIcon,
        pathname: '/admin/pickups/created',
      },
      {
        label: 'Completed',
        icon: ListIcon,
        pathname: '/admin/pickups/processed',
      },
      {
        label: 'Scan',
        icon: ListIcon,
        pathname: '/admin/pickups/scan-pickup',
      },
    ],
  },
  // TODO REMOVE THIS WHEN RECEPTACLE IMPLEMENTATION IS APPROVED
  // {
  //   label: 'Unitizer',
  //   icon: (fontSize) => <FaBarcode style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
  //   roles: ['admin', 'warehouse', 'analyst', 'wholesaler'],
  //   children: [
  //     {
  //       label: 'Load',
  //       icon: ListIcon,
  //       pathname: '/admin/cn33-load-receptacle',
  //     },
  //     {
  //       label: 'List',
  //       icon: ListIcon,
  //       pathname: '/admin/cn33-receptacle-list',
  //     },
  //     // {
  //     //   label: 'Already Informed', icon: ListIcon, pathname: '/admin/unitizers-informed'
  //     // },
  //   ],
  // },
  {
    label: 'Receptacles',
    icon: (fontSize) => <FaBarcode style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
    roles: ['admin', 'warehouse', 'analyst', 'wholesaler'],
    children: [
      {
        label: 'Dashboard',
        icon: DashboardIcon,
        pathname: '/admin/receptacle-dashboard',
      },
      {
        label: 'List',
        icon: ListIcon,
        pathname: '/admin/receptacle-list',
      },
    ],
  },
  {
    label: 'Receptacle Types',
    icon: (fontSize) => <FaBarcode style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
    roles: ['admin'],
    children: [
      {
        label: 'List',
        icon: ListIcon,
        pathname: '/admin/receptacle-type-list',
      },
    ],
  },
  {
    label: 'Dispatches',
    icon: (fontSize) => <IoIosBarcode style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
    roles: ['admin', 'warehouse', 'analyst', 'wholesaler'],
    children: [
      {
        label: 'Load',
        icon: ListIcon,
        pathname: '/admin/load-dispatch',
      },
      {
        label: 'List',
        icon: ListIcon,
        pathname: '/admin/dispatch-list',
      },
      {
        label: 'Pending',
        icon: ListIcon,
        pathname: '/admin/dispatches-pending',
      },
      {
        label: 'Processed',
        icon: ListIcon,
        pathname: '/admin/dispatch-shipped',
      },
      {
        label: 'Airway Bills',
        icon: ListIcon,
        pathname: '/admin/airway-bills',
      },
    ],
  },
  {
    label: 'Undeliverable',
    icon: LocalShippingIcon,
    roles: ['admin', 'warehouse', 'analyst', 'reseller', 'retailer'],
    // pathname: '/admin/parcels-undeliverable',
    badge: true,
    badgeCallback: 'loadUndeliverable',
    badgeVar: 'countUndeliverable',
    children: [
      {
        label: 'Processed',
        icon: ListIcon,
        pathname: '/admin/parcels-undeliverable/processed',
      },
      {
        label: 'Action Required',
        icon: ListIcon,
        pathname: '/admin/parcels-undeliverable/action-required',
      },
      {
        label: 'Pending',
        icon: ListIcon,
        pathname: '/admin/parcels-undeliverable/pending',
      },
    ],
  },
  // {
  //   label: 'Invoices',
  //   pathname: '/admin/invoices',
  //   icon: PdfIcon,
  // },
  {
    label: 'Reports',
    icon: AccountBalanceIcon,
    roles: ['admin', 'accounting', 'accounting_chief'],
    children: [
      {
        label: 'Accounting Invoicing Report',
        icon: ListIcon,
        pathname: '/admin/reports',
      },
      {
        label: 'Colombia 4-72 Manifest',
        icon: ListIcon,
        pathname: '/admin/reports-colombia-manifest',
      },
      // {
      //   label: 'Import Unitizers Dispatches Report',
      //   icon: ListIcon,
      //   pathname: '/admin/unitizers-dispatches-report',
      // },
      {
        label: 'Import Dispatches Report',
        icon: ListIcon,
        pathname: '/admin/dispatches-report',
      },
      {
        label: 'Claims',
        icon: ListIcon,
        pathname: '/admin/claims',
      },
      {
        label: 'BPS KPI',
        icon: ListIcon,
        pathname: '/admin/bps-kpi',
      },
      {
        label: 'Customs Alerts',
        icon: ListIcon,
        pathname: '/admin/customs-alerts',
      },
    ],
  },
  {
    label: 'Settings',
    icon: SettingsIcon,
    roles: ['admin'],
    children: [
      {
        label: 'Zones',
        icon: LanguageIcon,
        children: [
          {
            label: 'Countries',
            icon: GlobeIcon,
            pathname: '/admin/rates-countries',
          },
          {
            label: 'States',
            icon: ListIcon,
            pathname: '/admin/rates-states',
          },
          {
            label: 'Postal Codes',
            icon: ListIcon,
            pathname: '/admin/rates-postalcodes',
          },
        ],
      },
      // {
      //   label: 'Surchages',
      //   icon: ListIcon,
      //   children: [
      //     {
      //       label: 'Types',
      //       icon: ListIcon,
      //       pathname: '/admin/surchages-types',
      //     },
      //     {
      //       label: 'Airlines',
      //       icon: ListIcon,
      //       pathname: '/admin/surchages-airlines',
      //     },
      //   ],
      // },
      // {
      //   label: 'Rates',
      //   icon: ListIcon,
      //   pathname: '/admin/rates-rates',
      // },
      // {
      //   label: 'Billing',
      //   icon: ListIcon,
      //   children: [
      //     {
      //       label: 'Billing Tiers',
      //       icon: ListIcon,
      //       pathname: '/admin/rates-billing-tiers',
      //     },
      //     {
      //       label: 'Billing Factors',
      //       icon: ListIcon,
      //       pathname: '/admin/rates-billing-factors',
      //     },
      //   ],
      // },
      // {
      //   label: 'Assign Airwaybills',
      //   icon: (fontSize) => <PdfIcon style={{ fill: '#fff' , fontSize: fontSize || 14 }} />,
      //   pathname: '/admin/assign-airwaybills',
      // },
      {
        label: 'Service Deposits',
        icon: (fontSize) => <PricingIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
        pathname: '/admin/service-deposits',
      },
      {
        label: 'Pricing',
        icon: (fontSize) => <MoneyIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
        children: [
          {
            label: 'List Pricing',
            icon: (fontSize) => <MoneyIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/list-pricing',
          },
          {
            label: 'Compose Pricing',
            icon: (fontSize) => <MoneyIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/compose-pricing',
          },
          {
            label: 'Compose Costs',
            icon: (fontSize) => <MoneyIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/compose-costs',
          },
          {
            label: 'Weights',
            icon: FitnessCenterIcon,
            pathname: '/admin/weights',
          },
          {
            label: 'Money Ranges',
            icon: MoneyIcon,
            pathname: '/admin/money-ranges',
          },
          {
            label: 'Tariffs',
            icon: AddchartIcon,
            pathname: '/admin/tariffs',
          },
          {
            label: 'Markup',
            icon: TrendingUpIcon,
            pathname: '/admin/markup',
          },
          {
            label: 'Service Area Tariffs',
            icon: CompareArrowsIcon,
            pathname: '/admin/services-areas-tariffs',
          },
          {
            label: 'Costs',
            icon: WeightIcon,
            pathname: '/admin/services-areas-weights-costs',
          },
          {
            label: 'Insurance Costs',
            icon: VerifiedUser,
            pathname: '/admin/services-areas-insurance-costs',
          },
          {
            label: 'Surcharges',
            icon: PlusOneIcon,
            pathname: '/admin/services-areas-surcharges-costs',
          },
        ],
      },
      {
        label: 'Services',
        icon: ListIcon,
        children: [
          {
            label: 'Service Pickups',
            icon: (fontSize) => <AppsIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/service-service-pickup',
          },
          {
            label: 'Internal Services',
            icon: (fontSize) => <AppsIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/internal-services',
          },
          {
            label: 'Internal Services Routing',
            icon: (fontSize) => <AppsIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/internal-services-services',
          },
          {
            label: 'Services',
            icon: (fontSize) => <AppsIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/services',
          },
          {
            label: 'Service Countries',
            icon: (fontSize) => <GlobeIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/service-countries',
          },
          {
            label: 'Service Options',
            icon: (fontSize) => <AppsIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/service-options',
          },
          {
            label: 'Service Routes',
            icon: (fontSize) => <RouteIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
            pathname: '/admin/service-routes',
          },
          {
            label: 'Service Route Legs',
            icon: (fontSize) => (
              <MultiRouteIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />
            ),
            pathname: '/admin/service-route-legs',
          },
          {
            label: 'Harmonized Codes Restrictions',
            icon: () => <AppsIcon />,
            pathname: '/admin/harmonized-codes-restrictions',
          },
        ],
      },
      {
        label: 'Harmonized Codes',
        icon: () => <AppsIcon />,
        pathname: '/admin/harmonized-codes',
      },
      {
        label: 'External Services ',
        icon: ListIcon,
        children: [
          {
            label: 'Service Providers ',
            icon: ListIcon,
            pathname: '/admin/rates-service-providers',
          },
          {
            label: 'Services ',
            icon: ListIcon,
            pathname: '/admin/rates-services',
          },
          {
            label: 'Services Areas Tariffs ',
            icon: ListIcon,
            pathname: '/admin/rates-services-areas-tariffs',
          },
          {
            label: 'Services Areas ',
            icon: ListIcon,
            pathname: '/admin/rates-services-areas',
          },
          {
            label: 'Services Rules ',
            icon: ListIcon,
            pathname: '/admin/rates-services-rules',
          },
          {
            label: 'Services Rules type s',
            icon: ListIcon,
            pathname: '/admin/rates-services-rules-types',
          },
          {
            label: 'Services Providers Destinations ',
            icon: ListIcon,
            pathname: '/admin/rates-services-providers-destinations',
          },
          {
            label: 'Services Providers Countries ',
            icon: ListIcon,
            pathname: '/admin/rates-services-providers-countries',
          },
        ],
      },
      {
        label: 'Airports',
        icon: (fontSize) => <IoIosAirplane style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
        pathname: '/admin/airports',
      },
      {
        label: 'Airport Routes',
        icon: (fontSize) => <IoIosAirplane style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
        pathname: '/admin/airport-routes',
      },
      {
        label: 'Airports Offices',
        icon: (fontSize) => <IoIosBriefcase style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
        pathname: '/admin/airports-offices',
      },
      {
        label: 'Airlines',
        icon: (fontSize) => <IoMdAirplane style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
        pathname: '/admin/airlines',
      },
      {
        label: 'Tracking',
        icon: TrackingIcon,
        children: [
          {
            label: 'Tracking Codes',
            icon: TrackingCodeIcon,
            pathname: '/admin/tracking-codes',
          },
          {
            label: 'Tracking Codes Customers',
            icon: TrackingCodeIcon,
            pathname: '/admin/tracking-codes-customers',
          },
          {
            label: 'Tracking Codes Vendors',
            icon: TrackingCodeIcon,
            pathname: '/admin/tracking-codes-vendors',
          },
          {
            label: 'Cleaned Locations',
            icon: TrackingCodeIcon,
            pathname: '/admin/cleaned-locations',
          },
        ],
      },
      {
        label: 'Account Restrictions',
        icon: AccountTreeIcon,
        pathname: '/admin/account-service-restrictions',
      },
      {
        label: 'Account Parcel Limit Price',
        icon: AccountTreeIcon,
        pathname: '/admin/account-service-price-limits',
      },
      {
        label: 'Addresses',
        icon: LocationIcon,
        children: [
          {
            label: 'Addresses',
            icon: LocationIcon,
            pathname: '/admin/addresses',
          },
          {
            label: 'Address Details',
            icon: LocationIcon,
            pathname: '/admin/address-details',
          },
        ],
      },
      {
        label: 'Dropoff Stations',
        icon: AccountTreeIcon,
        pathname: '/admin/dropoff-stations',
      },
      {
        label: 'Facilities',
        icon: AccountTreeIcon,
        children: [
          {
            label: 'Facilities List',
            icon: TrackingCodeIcon,
            pathname: '/admin/facilities',
          },
          {
            label: 'Facilities Rules',
            icon: TrackingCodeIcon,
            pathname: '/admin/facilities-inbound-rules',
          },
        ],
      },
      {
        label: 'Quickbooks',
        icon: (fontSize) => <QuickbooksIcon style={{ fill: '#fff', fontSize: fontSize || 14 }} />,
        pathname: '/admin/quickbooks',
      },
      // {
      //   label: 'Inbound Countries',
      //   icon: (fontSize) => <IoIosGlobe style={{ fill: '#fff' , fontSize: fontSize || 14 }} />,
      //   pathname: '/admin/inbound-countries'
      // },
    ],
  },
  {
    label: 'Amazon Store',
    icon: StoreIcon,
    roles: ['admin', 'reseller', 'retailer', 'wholesaler'],
    children: [
      {
        label: 'Authorization',
        icon: KeyIcon,
        pathname: '/admin/stores/amazon/authorization',
      },
      {
        label: 'Orders',
        icon: ShoppingCartIcon,
        pathname: '/admin/stores/amazon/orders',
      },
    ],
  },
  {
    label: 'Stores',
    icon: StoreIcon,
    roles: ['admin', 'reseller', 'retailer', 'wholesaler', 'reseller_customer'],
    children: [
      {
        label: 'List',
        icon: ListIcon,
        pathname: '/admin/stores',
      },
      {
        label: 'Add new store',
        icon: AddBusinessIcon,
        pathname: '/admin/stores/new',
      },
      {
        label: 'Orders',
        icon: ShoppingCartIcon,
        pathname: '/admin/store-orders',
      },
    ],
  },
  {
    label: 'Security',
    icon: SecurityIcon,
    roles: ['admin'],
    children: [
      {
        label: 'Login Logs',
        icon: LogingLogsIcon,
        pathname: '/admin/login-logs',
      },
    ],
  },
];

// const pages: readonly MuiPage[] = [
//   {
//     pathname: '/getting-started',
//     icon: 'DescriptionIcon',
//     children: [
//       { pathname: '/getting-started/installation' },
//       { pathname: '/getting-started/usage' },
//       { pathname: '/getting-started/example-projects' },
//       { pathname: '/getting-started/templates' },
//       { pathname: '/getting-started/learn' },
//       { pathname: '/getting-started/faq', title: 'FAQs' },
//       { pathname: '/getting-started/supported-components' },
//       { pathname: '/getting-started/supported-platforms' },
//       { pathname: '/getting-started/support' },
//     ],
//   },
//   {
//     pathname: '/components',
//     icon: 'ToggleOnIcon',
//     children: [
//       {
//         pathname: '/components',
//         subheader: '/components/inputs',
//         children: [
//           { pathname: '/components/autocomplete' },
//           { pathname: '/components/buttons', title: 'Button' },
//           { pathname: '/components/button-group' },
//           { pathname: '/components/checkboxes', title: 'Checkbox' },
//           { pathname: '/components/floating-action-button' },
//           { pathname: '/components/radio-buttons', title: 'Radio button' },
//           { pathname: '/components/rating' },
//           { pathname: '/components/selects', title: 'Select' },
//           { pathname: '/components/slider' },
//           { pathname: '/components/switches', title: 'Switch' },
//           { pathname: '/components/text-fields', title: 'Text field' },
//           { pathname: '/components/transfer-list' },
//           { pathname: '/components/toggle-button' },
//         ],
//       },
//       {
//         pathname: '/components',
//         subheader: '/components/data-display',
//         children: [
//           { pathname: '/components/avatars', title: 'Avatar' },
//           { pathname: '/components/badges', title: 'Badge' },
//           { pathname: '/components/chips', title: 'Chip' },
//           { pathname: '/components/dividers', title: 'Divider' },
//           { pathname: '/components/icons' },
//           { pathname: '/components/material-icons' },
//           { pathname: '/components/lists', title: 'List' },
//           { pathname: '/components/tables', title: 'Table' },
//           { pathname: '/components/tooltips', title: 'Tooltip' },
//           { pathname: '/components/typography' },
//         ],
//       },
//       {
//         pathname: '/components',
//         subheader: '/components/feedback',
//         children: [
//           { pathname: '/components/alert' },
//           { pathname: '/components/backdrop' },
//           { pathname: '/components/dialogs' },
//           { pathname: '/components/progress' },
//           { pathname: '/components/skeleton' },
//           { pathname: '/components/snackbars', title: 'Snackbar' },
//         ],
//       },
//       {
//         pathname: '/components',
//         subheader: '/components/surfaces',
//         children: [
//           { pathname: '/components/accordion' },
//           { pathname: '/components/app-bar' },
//           { pathname: '/components/cards', title: 'Card' },
//           { pathname: '/components/paper' },
//         ],
//       },
//       {
//         pathname: '/components',
//         subheader: '/components/navigation',
//         children: [
//           { pathname: '/components/bottom-navigation' },
//           { pathname: '/components/breadcrumbs' },
//           { pathname: '/components/drawers', title: 'Drawer' },
//           { pathname: '/components/links', title: 'Link' },
//           { pathname: '/components/menus', title: 'Menu' },
//           { pathname: '/components/pagination' },
//           { pathname: '/components/speed-dial' },
//           { pathname: '/components/steppers', title: 'Stepper' },
//           { pathname: '/components/tabs' },
//         ],
//       },
//       {
//         pathname: '/components',
//         subheader: '/components/layout',
//         children: [
//           { pathname: '/components/box' },
//           { pathname: '/components/container' },
//           { pathname: '/components/grid' },
//           { pathname: '/components/stack' },
//           { pathname: '/components/image-list' },
//           { pathname: '/components/hidden' },
//         ],
//       },
//       {
//         pathname: '/components',
//         subheader: '/components/utils',
//         children: [
//           { pathname: '/components/click-away-listener' },
//           { pathname: '/components/css-baseline', title: 'CSS Baseline' },
//           { pathname: '/components/modal' },
//           { pathname: '/components/no-ssr', title: 'No SSR' },
//           { pathname: '/components/popover' },
//           { pathname: '/components/popper' },
//           { pathname: '/components/portal' },
//           { pathname: '/components/textarea-autosize' },
//           { pathname: '/components/transitions' },
//           { pathname: '/components/use-media-query', title: 'useMediaQuery' },
//         ],
//       },
//       {
//         pathname: '/components',
//         subheader: '/components/data-grid',
//         children: [
//           {
//             pathname: '/components/data-grid',
//             subheader: '/components/data-grid/overview',
//             title: 'Overview',
//           },
//           { pathname: '/components/data-grid/demo' },
//           { pathname: '/components/data-grid/getting-started' },
//           { pathname: '/components/data-grid/layout' },
//           { pathname: '/components/data-grid/columns' },
//           { pathname: '/components/data-grid/rows' },
//           { pathname: '/components/data-grid/editing' },
//           { pathname: '/components/data-grid/sorting' },
//           { pathname: '/components/data-grid/filtering' },
//           { pathname: '/components/data-grid/pagination' },
//           { pathname: '/components/data-grid/selection' },
//           { pathname: '/components/data-grid/events' },
//           { pathname: '/components/data-grid/export' },
//           { pathname: '/components/data-grid/components' },
//           { pathname: '/components/data-grid/style' },
//           { pathname: '/components/data-grid/localization' },
//           { pathname: '/components/data-grid/virtualization' },
//           { pathname: '/components/data-grid/accessibility' },
//           { pathname: '/components/data-grid/group-pivot', title: 'ðŸš§ Group & Pivot' },
//         ],
//       },
//       {
//         pathname: '/components',
//         subheader: '/components/lab',
//         children: [
//           { pathname: '/components/about-the-lab', title: 'About the lab ðŸ§ª' },
//           {
//             pathname: '/components',
//             subheader: '/components/lab-pickers',
//             title: 'Date / Time',
//             children: [
//               { pathname: '/components/pickers', title: 'Introduction' },
//               { pathname: '/components/date-picker' },
//               { pathname: '/components/date-range-picker', title: 'Date Range Picker âš¡ï¸' },
//               { pathname: '/components/date-time-picker' },
//               { pathname: '/components/time-picker' },
//             ],
//           },
//           { pathname: '/components/masonry' },
//           { pathname: '/components/timeline' },
//           { pathname: '/components/trap-focus' },
//           { pathname: '/components/tree-view' },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Component API',
//     pathname: '/api-docs',
//     icon: 'CodeIcon',
//     children: [
//       ...pagesApi,
//       {
//         pathname: '/api-docs/data-grid',
//         title: 'Data Grid',
//         children: [
//           { pathname: '/api-docs/data-grid', title: 'API Reference' },
//           { pathname: '/api-docs/data-grid/data-grid', title: 'DataGrid' },
//           { pathname: '/api-docs/data-grid/data-grid-pro', title: 'DataGridPro' },
//           { pathname: '/api-docs/data-grid/grid-api', title: 'GridApi' },
//           { pathname: '/api-docs/data-grid/grid-col-def', title: 'GridColDef' },
//           { pathname: '/api-docs/data-grid/grid-cell-params', title: 'GridCellParams' },
//           { pathname: '/api-docs/data-grid/grid-row-params', title: 'GridRowParams' },
//           {
//             pathname: '/api-docs/data-grid/grid-csv-export-options',
//             title: 'GridCSVExportOptions',
//           },
//           {
//             pathname: '/api-docs/data-grid/grid-print-export-options',
//             title: 'GridPrintExportOptions',
//           },
//         ].map((page) => {
//           return { ...page, linkProps: { as: page.pathname.replace(/^\/api-docs/, '/api') } };
//         }),
//       },
//     ]
//       .sort((a, b) =>
//         a.pathname.replace('/api-docs/', '').localeCompare(b.pathname.replace('/api-docs/', ''))
//       )
//       .map((page) => {
//         return { ...page, linkProps: { as: page.pathname.replace(/^\/api-docs/, '/api') } };
//       }),
//   },
//   {
//     pathname: '/system',
//     icon: 'BuildIcon',
//     children: [
//       { pathname: '/system/basics' },
//       { pathname: '/system/properties' },
//       { pathname: '/system/the-sx-prop', title: 'The sx prop' },
//       { pathname: '/system/borders' },
//       { pathname: '/system/display' },
//       { pathname: '/system/flexbox' },
//       { pathname: '/system/grid' },
//       { pathname: '/system/palette' },
//       { pathname: '/system/positions' },
//       { pathname: '/system/shadows' },
//       { pathname: '/system/sizing' },
//       { pathname: '/system/spacing' },
//       { pathname: '/system/screen-readers' },
//       { pathname: '/system/typography' },
//       { pathname: '/system/advanced' },
//       { pathname: '/system/box' },
//       { pathname: '/system/styled', title: 'styled' },
//     ],
//   },
//   {
//     pathname: '/customization',
//     icon: 'CreateIcon',
//     children: [
//       {
//         pathname: '/customization',
//         subheader: '/customization/theme',
//         children: [
//           { pathname: '/customization/theming' },
//           { pathname: '/customization/palette' },
//           { pathname: '/customization/dark-mode', title: 'Dark mode' },
//           { pathname: '/customization/typography' },
//           { pathname: '/customization/spacing' },
//           { pathname: '/customization/breakpoints' },
//           { pathname: '/customization/density' },
//           { pathname: '/customization/z-index', title: 'z-index' },
//           { pathname: '/customization/transitions' },
//           { pathname: '/customization/theme-components', title: 'Components' },
//           { pathname: '/customization/default-theme', title: 'Default Theme' },
//         ],
//       },
//       { pathname: '/customization/how-to-customize' },
//       { pathname: '/customization/color' },
//       { pathname: '/customization/unstyled-components' },
//     ],
//   },
//   {
//     pathname: '/guides',
//     title: 'How To Guides',
//     icon: 'VisibilityIcon',
//     children: [
//       { pathname: '/guides/api', title: 'API Design Approach' },
//       { pathname: '/guides/classname-generator', title: 'ClassName Generator' },
//       { pathname: '/guides/understand-mui-packages', title: 'Understand MUI packages' },
//       { pathname: '/guides/typescript', title: 'TypeScript' },
//       { pathname: '/guides/interoperability', title: 'Style Library Interoperability' },
//       { pathname: '/guides/styled-engine' },
//       { pathname: '/guides/minimizing-bundle-size' },
//       { pathname: '/guides/composition' },
//       { pathname: '/guides/routing' },
//       { pathname: '/guides/server-rendering' },
//       { pathname: '/guides/responsive-ui', title: 'Responsive UI' },
//       { pathname: '/guides/pickers-migration', title: 'Migration from @material-ui/pickers' },
//       { pathname: '/guides/migration-v4', title: 'Migration From v4' },
//       { pathname: '/guides/migration-v3', title: 'Migration From v3' },
//       { pathname: '/guides/migration-v0x', title: 'Migration From v0.x' },
//       { pathname: '/guides/testing' },
//       { pathname: '/guides/localization' },
//       { pathname: '/guides/content-security-policy', title: 'Content Security Policy' },
//       { pathname: '/guides/right-to-left', title: 'Right-to-left' },
//       { pathname: '/guides/flow' },
//     ],
//   },
//   {
//     pathname: '/styles',
//     title: 'Styles (legacy)',
//     icon: 'StyleIcon',
//     children: [
//       { pathname: '/styles/basics' },
//       { pathname: '/styles/advanced' },
//       { pathname: '/styles/api', title: 'API' },
//     ],
//   },
//   {
//     pathname: '/discover-more',
//     icon: 'AddIcon',
//     children: [
//       { pathname: '/discover-more/showcase' },
//       { pathname: '/discover-more/related-projects' },
//       { pathname: '/discover-more/roadmap' },
//       { pathname: '/discover-more/backers', title: 'Sponsors & Backers' },
//       { pathname: '/discover-more/vision' },
//       { pathname: '/discover-more/changelog' },
//       { pathname: '/discover-more/languages' },
//       { pathname: '/about', title: 'About us' },
//     ],
//   },
//   {
//     pathname: 'https://material-ui.com/store/',
//     title: 'Templates',
//     icon: 'ReaderIcon',
//     linkProps: {
//       'data-ga-event-category': 'store',
//       'data-ga-event-action': 'click',
//       'data-ga-event-label': 'sidenav',
//     },
//   },
//   { pathname: '/versions', ordered: false },
//   { pathname: '/', ordered: false, disableDrawer: true },
//   { pathname: 'https://medium.com/material-ui', title: 'Blog', icon: 'BookIcon' },
// ];

export default pages;
